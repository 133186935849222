import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'status', 'form'];

  notificationListener() {
    (document.querySelectorAll('.notification .delete') || []).forEach((delete_btn) => {
      const notification = delete_btn.parentNode;
  
      delete_btn.addEventListener('click', () => {
        notification.parentNode.removeChild(notification);
      });
    });
  }

  success(event) {
      const [data, status, xhr] = event.detail;
      this.statusTarget.innerHTML = xhr.response;
      this.inputTarget.value = '';
      this.formTarget.remove()
      this.notificationListener();
  }

  error(event) {
    const [data, status, xhr] = event.detail;
    this.statusTarget.innerHTML = xhr.response;
    this.inputTarget.value = '';
    this.notificationListener();
  }
}