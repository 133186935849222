import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "enabled"];

  toggle() {
    this.contentTargets.forEach((t) => t.classList.toggle(this.data.get("class")));
  }

  toggleEnableDisable() {
    this.enabledTargets.forEach((t) => {
      if (t.getAttribute("enabled")) {
        t.setAttribute("disabled", "disabled");
        t.removeAttribute("enabled", "");
      } else {
        t.setAttribute("enabled", "enabled");
        t.removeAttribute("disabled", "");
      }
    })
  }
}