import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "title", "url", "description", "tags", "hidden", "selectSave", "selectEvent", "disabled" ]

  connect() {
      console.log('connected save form')
  }

  selectSaveType () {
    this.showSave()
  }

  selectEventType () {
    this.showDate()
  }

  showDate() {
    this.selectSaveTarget.classList.remove("is-active");
    this.selectEventTarget.classList.add("is-active");

    this.hiddenTargets.forEach((t) => {
      if (t.getAttribute('value') == 'date_proof') {
        t.classList.remove("is-hidden")
      }
    })

    this.disabledTargets.forEach((t) => {
      t.setAttribute("enabled", "enabled");
      t.removeAttribute("disabled", "");
    })

  }

  showSave() {
    this.selectSaveTarget.classList.add("is-active");
    this.selectEventTarget.classList.remove("is-active");

    this.hiddenTargets.forEach((t) => {
      if (t.getAttribute('value') == 'date_proof') {
        t.classList.add("is-hidden")
      }
    })

    this.disabledTargets.forEach((t) => {
      t.setAttribute("disabled", "disabled");
      t.removeAttribute("enabled", "");
    })
  }


  get title() {
    return this.titleTarget.value;
  }

  get url() {
    return this.urlTarget.value;
  }

  get description() {
    return this.descriptionTarget.value;
  }

  get tags() {
    return this.tagsTarget.value;
  }


}