// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import Rails from "@rails/ujs";
window.Rails = Rails;

import "selectize";
import $ from "jquery";
window.jQuery = window.$ = require('jquery');

import * as calendar from '../components/calendar';
import * as directUpload from '../components/direct_upload';
import * as copyLink from '../components/copy_link';
import * as notification from '../components/notification';

var baseUrl = `${location.protocol}//${location.host}`

window.copyLink = copyLink;

document.addEventListener("turbolinks:load", event => {
  
  // Add google analytics
  // https://gist.github.com/giangnguyennet/75533a4537cd01121938a515a26b262c
  if (typeof ga === "function") {
    ga("set", "location", event.data.url);
    return ga("send", "pageview");
  }
});

document.addEventListener("turbolinks:load", () => {
  calendar.discoveryCalendar();
  notification.noticeListener();
  copyLink.setGlobalClipboard();
  copyLink.copyLinkListeners();
  directUpload.directUploadListener();
  directUpload.photoUploadListenerGeneric();

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach(el => {
      el.addEventListener("click", () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        $target.classList.toggle("is-active");
      });
    });
  }

  $(".js-save-quick-select.to_watch").on("click", function() {
    document.getElementById("saves_saves_save_action0").selectedIndex = 1;
  });

  $(".js-save-quick-select.to_read").on("click", function() {
    document.getElementById("saves_saves_save_action0").selectedIndex = 2;
  });

  $(".js-save-form-more").on("click", function() {
    $(".save-date").toggleClass("js-hidden");
    $(".save-tags").toggleClass("js-hidden");
  });

  $("#js-save-description-to-bottom").on("click", function() {
    var input = $("#save-description");
    var len = input.val().length;
    input[0].focus();
    input[0].setSelectionRange(len, len);
  });

  // Share Share Modal
  const modalList = [
      'js-save-share-modal',
      'js-share-conversation-modal',
      'js-discovery-filter-modal'
  ]

  // Modal open listners
  modalList.forEach(modalName => {
    $(`.${modalName}-open`).on("click", function(e) {
      e.preventDefault();
      $(`.${modalName}`).addClass("is-active");
    });  
  })

  // Modal closer listener
  $(".js-modal-background").on("click", function() {
    console.log('background click');
    $(".js-modal").removeClass("is-active");
  });

  $(".js-modal-close").on("click", function(e) {
    console.log('close click');
    e.preventDefault();
    $(".js-modal").removeClass("is-active");
  });


  // Selectize
  $(".js-tags").selectize({
    delimiter: ",",
    maxItems: 5,
    selectOnTab: true,
    createOnBlur: true,
    valueField: "name",
    labelField: "name",
    searchField: "name",
    // add space below
    // https://github.com/selectize/selectize.js/blob/master/docs/usage.md
    create: true,
    render: {
      option: function(item, escape) {
        var tag_count = item.taggings_count
          ? "x" + escape(item.taggings_count)
          : "";
        return (
          "<div>" +
          '<span class="tag-name has-text-weight-bold">' +
          escape(item.name) +
          "</span>" +
          '<span class="tag-taggings-count has-text-weight-light has-text-grey"> ' +
          tag_count +
          "</span>" +
          "</div>"
        );
      }
    },
    load: function(query, callback) {
      if (!query.length) return callback();
      $.ajax({
        url: baseUrl + "/users/tags?q=" + encodeURIComponent(query),
        type: "GET",
        error: function() {
          callback();
        },
        success: function(res) {
          callback(res.slice(0, 10));
        }
      });
    }
  });


  var baseUrl = `${location.protocol}//${location.host}`;

  var REGEX_EMAIL =
    "([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@" +
    "(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)";

$(".js-group-users").selectize({
    valueField: "id",
    labelField: "username",
    searchField: "username",
    highlight: false,
    create: false,
    maxItems: 5,
    render: {
      option: function(item, escape) {
        return (
          '<div class="share-user">' +
            (item.profile_image_url ? `<img src=${item.profile_image_url} class="share-user_image" alt='profile picture'/>` : '') +
            '<div class="share-username">' +
            (item.username ? escape(item.username) : '') +
            "</div>" +
          '</div>'
        );
      }
    },
    load: function(query, callback) {
      if (!query.length) return callback();
      $.ajax({
        url: baseUrl + "/users/shareable?q=" + encodeURIComponent(query),
        type: "GET",
        error: function() {
          callback();
        },
        success: function(res) {
          callback(res.slice(0, 10));
        }
      });
    }
    ,
    createFilter: function(emailInput) {
      var match, regex;

      // email@address.com
      regex = new RegExp("^" + REGEX_EMAIL + "$", "i");
      match = emailInput.match(regex);
      if (match) return !this.options.hasOwnProperty(match[0]);
      return false;
    },
    create: function(emailInput) {
      // create new input
      if (new RegExp("^" + REGEX_EMAIL + "$", "i").test(emailInput)) {
        return { email: emailInput, username: `invite: ${emailInput}`, id: emailInput  };
      }

      alert("Invalid email address.");
      return false;
    }
  });














});



















import "controllers"
