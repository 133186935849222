const noticeListener = () => {
  (document.querySelectorAll('.notification .delete') || []).forEach((delete_btn) => {
    const notification = delete_btn.parentNode;

    delete_btn.addEventListener('click', () => {
      notification.parentNode.removeChild(notification);
    });
  });
}

export { 
  noticeListener
};


