import $ from "jquery";

const setGlobalClipboard = () => {
    console.log("setting global clipboard");
  window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;
  
    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }
  
    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.readOnly = true;
        textArea.value = text;
        document.body.appendChild(textArea);
    }
  
    function selectText() {
        var range,
            selection;
  
        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }
  
    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }
  
    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };
  
    return {
        copy: copy
    };
  })(window, document, navigator);
  
  }
  
  
  const copyLinkListeners = () => {
      const copyTextClass = ".js-copy-link-text";
      const jsHiddenLinkClass = ".js-hidden-link";
  
      (document.querySelectorAll('.js-copy-link') || []).forEach((copyLink) => {
        copyLink.addEventListener('click', function(event) {
  
          $(this).find(copyTextClass).text("Copied!");
          setTimeout(() => {
            $(this).find(copyTextClass).text("Copy Link");
          }, 2000);
  
          var link = $(this).find(jsHiddenLinkClass).text();
          // copyTextToClipboard(link);
          window.Clipboard.copy(link);
        });
      });
  }
  
  const copyLinkMain = () => {
      setGlobalClipboard();
      copyLinkListeners();
  }
  
  export {
      setGlobalClipboard, copyLinkListeners
  }
  
  
  
  