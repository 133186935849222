import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkUpErrors", "checkUpButtons", "form", "precision", "details", "url", "description"];

  postSameDate() {
    this.precisionTarget.value = 'same_date';
    Rails.fire(this.formTarget, 'submit')
  }

  dateUpdated() {
    this.precisionTarget.value = "date_announcement";
    this.detailsTarget.classList.remove("is-hidden");
  }

  onPostSuccess(event) {
    // this.formTarget.replaceWith("Thanks! Your check up has been submitted.")

    let [data, status, xhr] = event.detail;
    this.formTarget.innerHTML = xhr.response;
    this.checkUpErrorsTarget.innerText = "";
  }

  onPostError(event) {
    console.log('on post error', event);
    let [data, status, xhr] = event.detail;
    this.checkUpErrorsTarget.innerHTML = xhr.response;
  }
}