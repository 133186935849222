import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "recipient", "message" ]

  search() {
    const baseUrl = `${location.protocol}//${location.host}`
    fetch(`${baseUrl}/shares/recipients?q=${encodeURIComponent(this.query)}`)
    .then(response => response.text())
    .then(html => {
      this.element.innerHTML = html
    })
  }

  connect() {
    console.log('shre controller consdf');
  }

  closeWindow(event) {
    console.log('close window');
    $(".js-modal").removeClass("is-active");
  }

  recipientIds(recipientList) {
    return recipientList.map((el, i) => {
      if (!el.classList.contains('is-hidden')) {
        return el.getAttribute("share-group-id")
      }
    })
  }

  get query() {
    return this.queryTarget.value;
  }

  get message() {
    return this.messageTarget.value;
  }

  get recipients() {
    return this.recipientTargets;
  }
}