import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';

const getParameters = (paramName) => {
   const url_string = window.location.href;
   const url = new URL(url_string);
   return url.searchParams.get(paramName);
 } 
 
 const discoveryCalendar = () => {
   var baseUrl = `${location.protocol}//${location.host}${location.pathname}`;
   const dateParams = getParameters("date");
   const dates = [dateParams];
 
   function highlightDays(date) {
       for (var i = 0; i < dates.length; i++) {
         if (new Date(dates[i]).toString() == date.toString()) {              
                       return [true, 'highlight'];
               }
       }
       return [true, ''];
    } 
   
    function defaultDate(date) {
      var dateStr = date ? date : undefined;
       return new Date(dateStr);
    }
 
 
     $("#discovery-calendar").datepicker({
      // http://www.spiceforms.com/blog/highlight-particular-dates-jquery-ui-datepicker/
       prevText: "Earlier",
       dateFormat: 'mm/dd/yy',
       defaultDate: defaultDate(dateParams),
       beforeShowDay: highlightDays,
       onSelect: function(dateText, obj) {
         var url = `${baseUrl}?date=${encodeURIComponent(dateText)}`;
         window.location.replace(url);
       }
     });
 }
 
export { 
   discoveryCalendar
};
