import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "button"];

  inputListener() {
    const t = this.buttonTarget;

    if (this.inputTarget.value.length === 0) {
      t.setAttribute("disabled", "disabled");
      t.removeAttribute("enabled", "");
    } else {
      t.setAttribute("enabled", "enabled");
      t.removeAttribute("disabled", "");
    }
  }
}