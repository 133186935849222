import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [ "checkUps", "seconds" ]

  connect() {

    this.subscription = consumer.subscriptions.create({ channel: "ActivityChannel"}, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
    this.incrementSeconds()
  }


  incrementSeconds() {
    // this.secondsTarget.innerHTML = "10"

    var y = this.secondsTarget;
    console.log(y);
    var i = 1;

    var interval = setInterval( increment, 1000);
    
    function increment(){
        i = i % 360 + 1;
        y.innerHTML = i;
    }
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }

  _connected() {
  }

  _disconnected() {
  }

  _received(data) {
    if (data.message) {
      this.checkUpsTarget.innerHTML = data.message;

      this.incrementSeconds()
      
    }
  }
}