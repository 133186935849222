import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "after",
    "before",
    "month",
    "period",
    "range",
    "start_date_end_date_end_time",
    "start_date_end_date",
    "start_date_start_time_end_date_end_time",
    "start_date_start_time_end_date",
    "start_date_start_time",
    "start_date",
    "status",
    "year",
    "date_proof",
    "type_button",
    "expected_type_button",
    "expected_types"
  ]

  connect() {
    this.setForm();
  }
    
  getState() {
    return {
      start_datetime: this.date_proofTarget.getAttribute('date_proof_start_datetime'),
      end_datetime: this.date_proofTarget.getAttribute('date_proof_end_datetime'),
      period: this.date_proofTarget.getAttribute('date_proof_period'),
      precision: this.date_proofTarget.getAttribute('date_proof_precision'),
      group: this.date_proofTarget.getAttribute('date_proof_group')
    }
  }

  setState(state) {
    if (state['start_datetime']) { this.date_proofTarget.setAttribute('date_proof_start_datetime', state['start_datetime']); }
    if (state['end_datetime']) { this.date_proofTarget.setAttribute('date_proof_end_datetime', state['end_datetime']); }
    if (state['period']) { this.date_proofTarget.setAttribute('date_proof_period', state['period']); }
    if (state['precision']) { this.date_proofTarget.setAttribute('date_proof_precision', state['precision']); }
    if (state['group']) { this.date_proofTarget.setAttribute('date_proof_group', state['group']); }
  }

  setForm() {
    const state = this.getState();
    const group = state['group'];
    const precision = state['precision'];

    this.highlightTypeButtons(group, precision)
    this.showInputs()
  }

  selectType(el) {
    const type = el.target.getAttribute('type');

    // this.setState({

    // })

    this.setForm()
  }

  highlightTypeButtons() {
    this.highlight_button(this.type_buttonTargets, group);
    if (group == 'expected') {
      this.highlight_button(this.expected_type_buttonTargets, precision);
    }
  }

  showInputs() {
    const state = this.getState();
    const group = state['group'];
    const precision = state['precision'];




    // console.log(this.afterTarget.nodeName);



    // hide(block)
    // hideAndDisable(inputs)
    // makeVisible(block)
    // makeVisibleAndEnable(inputs)
  }

  unhideExpected() {
    makeVisibleAndEnable(this.expected_typesTargets);
  }  

  hideAndDisable(targets) {
    targets.forEach((el, i) => {
      el.setAttribute("disabled", "disabled");
      el.removeAttribute("enabled", "");
      el.classList.add('is-hidden')
    })
  }

  makeVisibleAndEnable(targets) {
    targets.forEach((el, i) => {
      el.setAttribute("enabled", "enabled");
      el.removeAttribute("disabled", "");
      el.classList.remove('is-hidden')
    })
  }

  highlight_button(targets, value) {
    targets.forEach((el, i) => {
      if (value === el.getAttribute('value')) {
        el.classList.add('is-info');
      } else {
        el.classList.remove('is-info');
      }
    })
  }

  // select_date_type(button) {
  //   const buttonValue = button.target.getAttribute('value');
  //   this.highlight_button(this.type_buttonTargets, buttonValue);

  //   if (buttonValue === 'expected') {
  //     this.makeVisibleAndEnable(this.expected_typesTargets);
  //   } else {
  //     this.hideAndDisable(this.expected_typesTargets);
  //   }
  // }
  
  // select_expected_type(button) {
  //   const buttonValue = button.target.getAttribute('value');
  //   this.highlight_button(this.expected_type_buttonTargets, buttonValue);
  // }

}