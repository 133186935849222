const directUploadListener = () => {
  addEventListener("direct-upload:initialize", event => {
    console.log('adding event listener');
      const { target, detail } = event
      const { id, file } = detail
      target.insertAdjacentHTML("afterend", `
        <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename">Uploading ${file.name}...</span>
        </div>
      `)
    })
     
    addEventListener("direct-upload:start", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.remove("direct-upload--pending")
    })
     
    addEventListener("direct-upload:progress", event => {
      const { id, progress } = event.detail
      const progressElement = document.getElementById(`direct-upload-progress-${id}`)
      progressElement.style.width = `${progress}%`
    })
     
    addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const { id, error } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add("direct-upload--error")
      element.setAttribute("title", error)
    })
     
    addEventListener("direct-upload:end", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add("direct-upload--complete")
    })   
};


const photoUploadListener = (input, infoArea) => {
  if (!input) { return; }
  input.addEventListener('change', showFileName );
  
  function showFileName( event ) {
  
      // the change event gives us the input it occurred in 
      var input = event.srcElement;
      
      // the input has an array of files in the `files` property, each one has a name that you can use. We're just using the name here.
      var fileName = input.files[0].name;
      
      // use fileName however fits your app best, i.e. add it into a div
      infoArea.textContent = 'File name: ' + fileName;
  }
}

const photoUploadListenerGeneric = () => {
  const input = document.getElementById('js-photo-upload');
  const infoArea = document.getElementById('js-photo-upload-filename');
  photoUploadListener(input, infoArea);
}

export { 
  directUploadListener,
  photoUploadListenerGeneric
};